import {
    Box,
    Button,
    LinearProgress,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import isEmail from 'is-email';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const [errorMessage, setErrorMessage] =
        useState(undefined);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const { signUp } = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const { email, password, name, addaUsername } = data;
        setIsSigningUp(true);
        setErrorMessage(undefined);
        try {
            await signUp({ email, password, name, addaUsername });
            navigate('/');
        } catch (error) {
            const res = error.response;
            if (res) {
                const code = res.data?.error?.code;
                console.log(code)
                if (code === 'email-already-in-use') {
                    setError('email', {
                        message: 'This email is taken',
                    });
                    return;
                }
                if (code === 'duplicate-adda-username') {
                    setError('addaUsername', {
                        message: `Username already entered.`,
                    });
                    return;
                }
                if (code === 'weak-password') {
                    setError('password', {
                        message: `Weak Password`,
                    });
                    return;
                }
            }
            setErrorMessage("Can't sign up right now");
        } finally {
            setIsSigningUp(false);
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                input: { color: 'white' }
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: 'Enter your name' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Name"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}  
                            helperText={errors?.name?.message}
                            error={Boolean(errors?.name)}
                            sx={{ marginBottom: 2, }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="email"
                    rules={{
                        required: 'Enter an email',
                        validate: {
                            validateEmail: (email) =>
                                isEmail(email) || 'Enter a valid email',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}  
                            helperText={errors?.email?.message}
                            error={Boolean(errors.email)}
                            type="email"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="password"
                    inputProps={{style: {color: 'white', }}}
                    rules={{ required: 'Enter a password' }}
                    render={({ field }) => (
                        <TextField
                            label="Password"
                            {...field}
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}  
                            helperText={errors?.password?.message}
                            error={Boolean(errors.password)}
                            sx={{ marginTop: 2 }}
                            type="password"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="addaUsername"
                    rules={{ required: 'Enter an App Username' }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Adda52 Username"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}                            
                            helperText={errors?.addaUsername?.message}
                            error={Boolean(errors?.addaUsername)}
                            sx={{ marginTop: 2 }}
                        />
                    )}
                />
                <LinearProgress
                    variant="indeterminate"
                    sx={{
                        marginTop: 2,
                        visibility: isSigningUp ? 'visible' : 'hidden',
                    }}
                />
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ marginTop: 2 }}
                >
                    Sign up
                </Button>
                <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                    <Typography
                        sx={{
                            visibility: errorMessage
                                ? 'visible'
                                : 'hidden',
                        }}
                        color="error"
                    >
                        {errorMessage}
                    </Typography>
                </Box>
            </form>
        </Box>
    );
}