import React from "react";
import signinsvg from "../assets/signin.svg";
import backarrowsvg from "../assets/backarrow.svg";
import SignIn from "../routes/SignIn";
import { Zoom } from "react-awesome-reveal";

const Loginmobile = () => {
  return (
    <div className="max-[900px]:flex max-[900px]:flex-col max-[900px]pt-6 hidden">
      <div className="flex items-center px-4 mt-6">
        <img
          className="w-1/8 mr-4 cursor-pointer transition-all"
          src={backarrowsvg}
          alt="back"
          onClick={()=>{window.history.back()}}
        />
        <Zoom triggerOnce><img className="w-2/3" src={signinsvg} alt="" /></Zoom>
      </div>
      <Zoom triggerOnce className="flex flex-col font-base items-center justify-center aboutTextFont px-6 pt-6 text-xl">
        <div>Welcome to the vibrant world of XTasy'23! Get ready to flaunt your
        bell-bottoms and dance the night away amidst a shower of colourful
        confetti.</div>
        
        <div className="flex flex-col items-center justify-center py-6">
          <p>Not Registered?</p>
          <button
            onClick={() => {
              window.location.href = "register";
            }}
            className="font-semibold border-2 px-3 py-1 my-2 rounded-xl bg-[#FFB097] border-black text-xl max-[640px]:text-base transition-all"
          >
            Sign Up and Grab Your Passes!
          </button>
        </div>
      </Zoom>
    
      <div className="flex flex-col rounded-xl bg-registermobilebg bg-bottom bg-contain bg-no-repeat items-center justify-center p-12 pl-6 pb-0 transition-all">
        <Zoom className="my-16"><SignIn /></Zoom>
      </div>
    </div>
  );
};

export default Loginmobile;
