const Video = [
    {
        key: "1",
        name: "Prashant Chettiar",
        position: "Video Editing Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/PrashantChettiarVideoEditing.jpg"),
        bgColour: "#FFDAB9", // Medium Slate Blue
    },


];

export default Video;