const Events = [
    {
        key: "1",
        name: "Muskaan Bhardwaj",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/MuskaanBhardwajEM.jpg"),
        bgColour: "#E6E6FA", // Lavender
    },
    {
        key: "2",
        name: "Vasudev Pant",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/VasudevPantEM.jpg"),
        bgColour: "#ADD8E6", // Aquamarine
    }, {
        key: "3",
        name: "Yuvraj Ahuja",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/YuvrajAhujaEM.jpg"),
        bgColour: "#BDB76B", // Dark Khaki
    },
    {
        key: "4",
        name: "Aditya Raghav",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AdityaRaghavEM.jpg"),
        bgColour: "#BDB76B", // Dark Khaki
    },
];

export default Events;

