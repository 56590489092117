import React from "react";
import signupsvg from "../assets/signup.svg";
import backarrowsvg from "../assets/backarrow.svg";
import SignUp from "../routes/SignUp";
import { Zoom } from "react-awesome-reveal";
import QR from "../assets/qr.jpeg"

const Registermobile = () => {
  return (
    <div className="max-[900px]:flex max-[900px]:flex-col max-[900px]py-6 hidden">
      <div className="flex items-center px-4 mt-6">
        <img
          className="w-1/8 mr-4 cursor-pointer transition-all"
          src={backarrowsvg}
          alt="back"
          onClick={()=>{window.history.back()}}
        />
        <Zoom triggerOnce><img className="w-2/3" src={signupsvg} alt="" /></Zoom>
      </div>
      <Zoom triggerOnce className="flex flex-col items-center justify-center aboutTextFont px-10 pt-4">
        <div>
          1. Download and Install the <strong>APP</strong> using this QR
          <br />
          <br />
          <img src={QR} width="80%" style={{marginLeft: "10%"}}/><br/><br/>
          2. Click on <strong>SIGN UP</strong> (Enter as a New User only).
          Verify your mobile number by clicking on <strong>GET OTP</strong>
          <br />
          <br />
          3. On the top left corner of the main screen, tap on{" "}
          <strong>My Profile</strong> --> <strong>Personal Details</strong> -->{" "}
          <strong>User Details</strong> --> <strong>Username</strong><br/>
          4. Sign up on our website using your <strong>unique</strong> app username.<br/><br/>
          <strong>Please note :</strong><br/>
          If you try to sign up using an existing Adda52 account or a false username, then you won't be able to get a pass on your Email ID.
        </div>
        <div className="flex flex-col mb-5 items-center justify-center mt-6">
          <p>Already Registered?</p>
          <button
            onClick={() => {
              window.location.href = "signin";
            }}
            className="font-semibold border-2 px-3 py-1 my-2 rounded-xl bg-[#FFB097] border-black text-xl max-[640px]:text-base transition-all"
          >
            Awesome, Sign In Here!
          </button>
        </div>
      </Zoom>
      <Zoom triggerOnce className="pb-10 flex flex-col rounded-xl bg-registermobilebg bg-bottom bg-contain bg-no-repeat items-center justify-center p-12 pl-6 pb-0">
        <SignUp/>
      </Zoom>
    </div>
  );
};

export default Registermobile;
