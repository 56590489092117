const Decorations = [
    {
        key: "1",
        name: "Arohi Jain",
        position: "Decorations Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/ArohiJainDecorations.jpg"),
        bgColour: "#F0E68C", // Khaki
    },
    {
        key: "2",
        name: "Khushi Kamboj",
        position: "Decorations Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/KhushiKambojDecorations.jpg"),
        bgColour: "#FFE4E1", // Hot Pink
    },


];

export default Decorations;