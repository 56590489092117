import { React, useState } from 'react'
import footerlogo from '../../assets/footer-logo.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
    const [active, setActive] = useState("");
    return (


        <div class="flex items-end w-full bg-white">
            <footer class="w-full text-gray-700 bg-footer-bg body-font bg-center bg-cover">
                <div class="container flex flex-col flex-wrap px-5 py-14 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap">
                    <div class="flex-shrink-0 w-64 mx-auto text-center bg-covers md:mx-0 md:text-left">
                        <a class="flex items-center justify-start font-medium text-gray-900 title-font md:justify-start">
                            <img src={footerlogo} />
                        </a>
                    </div>
                    <div class="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
                        <div class="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 class="mb-3 font-medium tracking-widest text-gray-900 uppercase title-font text-sm  ">RESOURCES</h2>
                            <nav class="mb-10 list-none font-medium">
                                <li class="mt-3">
                                    <a class="text-gray-500 cursor-pointer hover:text-gray-900 text-xs"><Link to="/"
                                        onClick={() => {
                                            setActive("");
                                            window.scrollTo(0, 0);
                                        }}>ABOUT</Link></a>
                                </li>
                                <li class="mt-3">
                                    <a class="text-gray-500 cursor-pointer hover:text-gray-900 text-xs"><Link to="/events"
                                        onClick={() => {
                                            setActive("");
                                            window.scrollTo(0, 0);
                                        }}>EVENTS</Link></a>
                                </li>
                                <li class="mt-3">
                                    <a class="text-gray-500 cursor-pointer hover:text-gray-900 text-xs"><Link to="/pronite"
                                        onClick={() => {
                                            setActive("");
                                            window.scrollTo(0, 0);
                                        }}>PRONITE</Link></a>
                                </li>
                                <li class="mt-3">
                                    <a class="text-gray-500 cursor-pointer hover:text-gray-900 text-xs"><Link to="/team"
                                        onClick={() => {
                                            setActive("");
                                            window.scrollTo(0, 0);
                                        }}>TEAM</Link></a>
                                </li>
                            </nav>
                        </div>

                        <div class="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font ">Platform
                            </h2>
                            <nav class="mb-10 list-none">
                                {/* <li class="mt-3">
                                <a class="text-gray-500 cursor-pointer hover:text-gray-900">Terms &amp; Privacy</a>
                            </li>
                            <li class="mt-3">
                                <a class="text-gray-500 cursor-pointer hover:text-gray-900">Pricing</a>
                            </li> */}
                                <li class="mt-3">
                                    <a class="text-gray-500 cursor-pointer hover:text-gray-900 text-xs"><Link to="/register"
                                        onClick={() => {
                                            setActive("");
                                            window.scrollTo(0, 0);
                                        }}>Sign Up</Link></a>
                                </li>
                            </nav>
                        </div>
                        <div class="w-full px-4 lg:w-1/3 md:w-1/2">
                            <h2 class="mb-3 text-sm font-medium tracking-widest text-gray-900 uppercase title-font">Follow us
                            </h2>

                            <div class="mt-4">
                                <span class="inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start">

                                    <a class="ml-3 text-gray-500 cursor-pointer hover:text-gray-700" href="https://www.instagram.com/xtasy_bvcoe/">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                        </svg>
                                    </a>

                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className='text-center text-gray-500 cursor-pointer hover:text-gray-900 text-xs'>Made with ❤ by <a href='www.google.com'>Lakshay</a>, Aryan, Sanidhya, Sparsh</div> */}

            </footer>

        </div>
    )
}

export default Footer
