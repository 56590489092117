const Food = [
    {
        key: "19",
        name: "Yashovardhan Singh",
        position: "FnB Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/YashovardhanSinghFnB.jpg"),
        bgColour: "#FF6347", // Tomato
    },
    {
        key: "4",
        name: "Archit Sharma",
        position: "FnB Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/ArchitSharmaF_B.jpg"),
        bgColour: "#FFC0CB", // Pink
    },
];

export default Food;

