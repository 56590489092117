const Communications = [{
    key: "1",
    name: "Harshita Gupta",
    position: "Communications Head",
    image: "",
    twitter: "https://twitter.com/johndoe",
    instagram: "https://www.instagram.com/shivamkapurr/",
    linkedin: "https://www.instagram.com/shivamkapurr/",
    cardPhoto: require("../../assets/team/headphoto/HarshitaGuptaCommunications.jpg"),
    bgColour: "#FFD700", // Gold
},
{
    key: "2",
    name: "Taranpreet Singh",
    position: "Communications Head",
    image: "",
    twitter: "https://twitter.com/johndoe",
    instagram: "https://www.instagram.com/shivamkapurr/",
    linkedin: "https://www.instagram.com/shivamkapurr/",
    cardPhoto: require("../../assets/team/headphoto/TaranpreetSinghCommunications.jpg"),
    bgColour: "#FFDAB9", // Blue Violet
},

];

export default Communications;

