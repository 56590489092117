import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import firebaseConfig from './firebase.config';

initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';

// import {
//   BrowserRouter,
//   Route,
//   Routes,
// } from 'react-router-dom';
// import SignIn from './routes/SignIn';
// import { AuthProvider } from './auth';
// import { initializeApp } from 'firebase/app';
// import firebaseConfig from './firebase.config';
// import SignUp from './routes/SignUp';

// initializeApp(firebaseConfig);

// const root = ReactDOM.createRoot(
//   document.getElementById('root')
// );
// root.render(
//   <React.StrictMode>
//     <AuthProvider>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<App />} />
//           <Route path="/signin" element={<SignIn />} />
//           <Route path="/signup" element={<SignUp />} />
//         </Routes>
//       </BrowserRouter>
//     </AuthProvider>
//   </React.StrictMode>
// );

