import React, { useState } from "react";
import { Suspense, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { useFrame } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

import CanvasLoader from "../Loader";

const Magic = ({ isMobile }) => {
  const ipad = useGLTF("./xtasyobject/scene.gltf");

  const [rotation, setRotation] = useState(0);
  const [numRotations, setNumRotations] = useState(0);
  const maxRotations = 1; // Change this value to adjust the number of rotations

  useFrame(({ clock }) => {
    // Calculate the elapsed time and rotation angle
    const elapsed = clock.getElapsedTime();
    const newRotation = (elapsed * 6) % (2 * Math.PI);

    // Update the rotation angle and the number of rotations
    setRotation(newRotation);
    if (newRotation < rotation) {
      setNumRotations((prevNumRotations) => prevNumRotations + 1);
    }

    // Stop the rotation after the desired number of rotations is reached
    if (numRotations >= maxRotations) {
      setRotation(0);
    }
  });

  return (
    <mesh>
      <hemisphereLight intensity={0.2} groundColor="black" />
      <spotLight
        position={[-20, 40, 20]}
        angle={0.18}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={1024}
      />
      <pointLight intensity={1} />
      <primitive
        object={ipad.scene}
        scale={isMobile ? 0.023 : 0.03}
        // scale={0.03}
        // position={[-3.6, 0, 1]}
        position={isMobile ? [-2.85, 0, 1] : [-3.6, 0, 1]}
        // rotation={[-0.01, -0.2, -0.1]}
        rotation={[0, rotation, 0]}
      />
    </mesh>
  );
};

const MagicCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Add a listener for changes to the screen size
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    // Set the initial value of the `isMobile` state variable
    setIsMobile(mediaQuery.matches);

    // Define a callback function to handle changes to the media query
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    // Add the callback function as a listener for changes to the media query
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Remove the listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  });

  return (
    <Canvas
      frameloop="demand"
      shadows
      dpr={[1, 2]}
      camera={{ position: [20,12,35], fov: 14 }}
      // camera={{ position: [20, 17, 20], fov: 16 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Magic isMobile={isMobile} />
      </Suspense>

      <Preload all />
    </Canvas>
  );
};

export default MagicCanvas;
