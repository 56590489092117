const HeadDetails = [
    {
        key: "1",
        name: "Aditya Raghav",
        position: "Event Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AdityaRaghavEM.jpg"),
        bgColour: "#FFA07A", // Light Salmon
    },
    {
        key: "2",
        name: "Aman Singh",
        position: "Design Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AmanSinghDesign.jpg"),
        bgColour: "#FFB6C1", // Light Pink
    },
    {
        key: "3",
        name: "Anushka Sharma",
        position: "C_D Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AnushkaSharmaC_D.jpg"),
        bgColour: "#98FB98", // Pale Green
    },
    {
        key: "4",
        name: "Archit Sharma",
        position: "F_B Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/ArchitSharmaF_B.jpg"),
        bgColour: "#FFC0CB", // Pink
    },
    {
        key: "5",
        name: "Arohi Jain",
        position: "Decorations Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/ArohiJainDecorations.jpg"),
        bgColour: "#F0E68C", // Khaki
    },
    {
        key: "6",
        name: "Ekagrah Grover",
        position: "C_D Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/EkagrahGroverC_D.jpg"),
        bgColour: "#AFEEEE", // Pale Turquoise
    },
    {
        key: "7",
        name: "Gautmi Tomar",
        position: "Social Media Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/GautmiTomarSocialMedia.jpg"),
        bgColour: "#DDA0DD", // Plum
    },
    {
        key: "8",
        name: "Harshita Gupta",
        position: "Communications Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/HarshitaGuptaCommunications.jpg"),
        bgColour: "#FFD700", // Gold
    },
    {
        key: "9",
        name: "Hitesh Gandhi",
        position: "Event Coverage Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/HiteshGandhiEventCoverage.jpg"),
        bgColour: "#B0E0E6", // Powder Blue
    },
    {
        key: "10",
        name: "Khushi Kamboj",
        position: "Decorations Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/KhushiKambojDecorations.jpg"),
        bgColour: "#FF69B4", // Hot Pink
    },
    {
        key: "11",
        name: "Muskaan Bhardwaj",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/MuskaanBhardwajEM.jpg"),
        bgColour: "#E6E6FA", // Lavender
    },
    {
        key: "12",
        name: "Paakhi Sharma",
        position: "Social Media Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/PaakhiSharmaSocialMedia.jpg"),
        bgColour: "#98FB98", // Deep Pink
    },
    {
        key: "13",
        name: "Prashant Chettiar",
        position: "Video Editing Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/PrashantChettiarVideoEditing.jpg"),
        bgColour: "#FFDAB9", // Medium Slate Blue
    },
    {
        key: "14",
        name: "Rishab Gulia",
        position: "Security Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RishabGuliaSecurity.jpg"),
        bgColour: "#FFFF00", // Yellow
    },
    {
        key: "15",
        name: "Rishabh Tyagi",
        position: "Security Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RishabhTyagiSecurity.jpg"),
        bgColour: "#FFE4B5", // Moccasin
    },
    {
        key: "16",
        name: "Riya Ghaloth",
        position: "Sponsorship Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RiyaGhalothSponsorship_.jpg"),
        bgColour: "#E0FFFF", // Light Cyan
    },
    {
        key: "17",
        name: "Taranpreet Singh",
        position: "Communications Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/TaranpreetSinghCommunications.jpg"),
        bgColour: "#FFDAB9", // Blue Violet
    },
    {
        key: "18",
        name: "Vasudev Pant",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/VasudevPantEM.jpg"),
        bgColour: "#7FFFD4", // Aquamarine
    },
    {
        key: "19",
        name: "Yashovardhan Singh",
        position: "FnB Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/YashovardhanSinghFnB.jpg"),
        bgColour: "#FF6347", // Tomato
    },
    {
        key: "20",
        name: "Yuvraj Ahuja",
        position: "EM Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/YuvrajAhujaEM.jpg"),
        bgColour: "#BDB76B", // Dark Khaki
    },
];

export default HeadDetails;
