import React, { useEffect } from "react";
import insta from '../assets/instagram.png'
import shape from '../assets/Shape.png'
import SignIn from "../routes/SignIn"
import signinsvg from '../assets/signin.svg'
import { Rotate, Zoom } from "react-awesome-reveal";
import backarrowsvg from "../assets/backarrow.svg"
import Loginmobile from "./Loginmobile";
import { Navigate } from "react-router-dom";
import { useAuth } from "../auth";


const SigninPage = () => {

  const auth = useAuth(undefined);

  useEffect(()=>{
    if(auth?.user) {
      window.alert("You are already loggedin! Please logout from menubar to visit this page.")
    }
  })
  
  return (
    <>
    {auth.user ? <Navigate to="/" /> :<>
    <div className="Register">
      <Rotate triggerOnce className="Register__insta"><img src={insta} alt="instagram" onClick={()=>{window.open("https://www.instagram.com/xtasy_bvcoe/","_blank")}} /></Rotate>
      <Zoom triggerOnce className="Register__heading" style={{right: "18%"}}><img src={signinsvg} width="300px"/></Zoom>
      <Zoom triggerOnce className="Register__info aboutTextFont">
        <div>
        Welcome to the vibrant world of <strong>XTasy'23!</strong><br/><br/>
        Get ready to flaunt your bell-bottoms and dance the night away amidst a shower of colourful confetti.
        </div>
      </Zoom>
      <Zoom triggerOnce className="Register__backarrow">
        <img src={backarrowsvg} onClick={()=>{window.history.back()}} alt="back arrow" width="35px" />
      </Zoom>
      <Zoom triggerOnce className="Register__button">
        <div>
          <p>Not Registered?</p>
          <button onClick={()=>{window.location.href = "register"}} className="font-semibold border-2 px-3 py-1 my-2 rounded-xl bg-[#FFB097] border-black text-xl max-[640px]:text-base">Sign Up and Grab Your Passes!</button>
        </div>
      </Zoom>
      
      <div className="Register__shape">
        <img height="100%" width="100%" src={shape} alt="instagram" />
        <Zoom triggerOnce>
          <div className="Register__form">
            <SignIn />
          </div>
        </Zoom>
      </div>
    </div>
    <Loginmobile />
    </>}
    </>
  )
}

export default SigninPage
