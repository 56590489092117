import axios from 'axios';
import { CLOUD_FUNCTIONS_ORIGIN } from './functions-origin';

const apiUrl = `https://us-central1-xtasy-e4d24.cloudfunctions.net/api`;

export async function signIn({ email, password }) {
    const url = `${apiUrl}/login`;
    const res = await axios.post(url, { email, password });
    return res.data;
}

export async function signUp({
    email,
    password,
    name,
    addaUsername
}) {
    const url = `${apiUrl}/register`;
    const res = await axios.post(url, {
        email,
        password,
        name,
        addaUsername
    });
    return res.data;
}

export async function getUserData({ userId }) {
    const url = `${apiUrl}/users/${userId}`;
    const res = await axios.get(url);
    return res.data;
}