import React from "react";
import aboutanimation from "../../assets/aboutanimation.gif";
import eventsmainpage from "../../assets/eventsmainpage.svg";
import teamsmainpage from "../../assets/teammainpage.svg";
import abouttext from "../../assets/about.svg";
import teammain from"../../assets/teammain.svg";
import awesomeevents from "../../assets/awesomeevents.svg"
import { Fade, Roll, Rotate, Zoom } from "react-awesome-reveal";

const AboutExtended = () => {
  return (
    <div id="about" className="flex flex-col px-24 max-[900px]:px-4 mt-6">
      <Zoom triggerOnce className="flex items-center justify-center rounded-lg">
        <img
          src={abouttext}
          alt="about"
          className="h-24 my-8 object-cover mouseHover"
        />
      </Zoom>
      <div className="flex flex-row max-[900px]:flex-col py-4">
        <Zoom triggerOnce direction="left" className="flex basis-1/2 justify-center overflow-hidden rounded-l-lg max-[900px]:rounded-lg">
          <img src={aboutanimation} alt="" width="100%" />
        </Zoom>
        <div className="rounded-r-lg max-[900px]:rounded-lg flex flex-col basis-1/2 bg-[#9DC5C9] text-black aboutTextFont font-semibold text-[22px] max-[900px]:text-[16px] leading-normal tracking-tight px-8 max-[900px]:px-4 py-6 text-justify items-center justify-around">
          <Zoom triggerOnce style={{fontFamily: "'Kanit', sans-serif",}}>
            XTasy is the cultural festival organized by the students of Bharati
            Vidyapeeth's College of Engineering, New Delhi. The festival is a
            celebration of music, dance, drama, art, and various other forms of
            cultural expression. The festival attracts students from different
            colleges and universities across the country. It is an opportunity
            for the students to showcase their talent and creativity in various
            cultural events such as singing, dancing, poetry, and drama
            competitions.
          </Zoom>
        </div>
      </div>
      <div className="flex flex-row max-[900px]:flex-col-reverse py-4">
        <div className="rounded-l-lg max-[900px]:rounded-lg flex flex-col basis-1/2 bg-[#9DC5C9] text-black aboutTextFont font-semibold text-[22px] max-[900px]:text-[16px] leading-normal tracking-tight px-8 max-[900px]:px-4 py-6 text-justify items-center justify-around">
          <Zoom triggerOnce style={{fontFamily: "'Kanit', sans-serif",}}>
            Enter the world of XTasy'23 - a magical realm where music, art, and
            culture collide! Join us for a journey into the heart of creativity,
            where you'll discover a kaleidoscope of colors, sounds, and
            experiences that will leave you breathless. Get ready to be
            teleported to a world of pure imagination, where anything is
            possible and the only limit is your own imagination. With
            electrifying and interactive activities that will blow your mind,
            XTasy'23 is the ultimate cultural fest that promises to leave you
            with memories to last a lifetime. Come and join us for a celebration
            like no other!
          </Zoom>
          <Zoom triggerOnce className="w-5/6 px-4 py-2 my-6 rounded-xl text-xl max-[900px]:text-base hover:scale-105">
          <button onClick={()=>{window.location.href = "/events"}} >
            <img src={awesomeevents} style={{backgroundColor: "rgba(0,0,0,0.2)", borderRadius: "5px", padding: "5px"}} alt="checkout out awesome events" />
          </button>
          </Zoom>
        </div>
        <Zoom triggerOnce direction="right" className="flex basis-1/2">
          <img src={eventsmainpage} alt="" className="rounded-r-lg max-[900px]:rounded-lg" />
        </Zoom>
      </div>
      <div className="flex flex-row max-[900px]:flex-col py-4">
        <Zoom triggerOnce direction="left" className="flex basis-1/2">
          <img src={teamsmainpage} alt="" className="rounded-l-lg max-[900px]:rounded-lg"/>
        </Zoom>
        <div className="flex flex-col basis-1/2 bg-[#9DC5C9] text-black aboutTextFont font-semibold text-[22px] max-[900px]:text-[16px] leading-normal tracking-tight px-8 max-[900px]:px-4 py-6 text-justify items-center justify-evenly rounded-r-lg max-[900px]:rounded-lg">
          <Zoom triggerOnce style={{fontFamily: "'Kanit', sans-serif",}}>
            Meet the incredible team behind XTasy'23 - a group of dedicated
            individuals who work tirelessly day and night to ensure that every
            aspect of the event is nothing short of extraordinary. From
            logistics and finance to design and decorations, this team has it
            all covered. Get ready to be blown away by the talent and hard work
            of this dream team!
          </Zoom>
          <Zoom triggerOnce className="w-5/6 px-4 py-2 my-6 rounded-xl text-xl max-[900px]:text-base hover:scale-105">
          <button onClick={()=>{window.location.href = "/team"}}>
            <img style={{backgroundColor: "rgba(0,0,0,0.2)", borderRadius: "5px", padding: "10px 5px"}} src={teammain} alt="checkout our team" />
          </button>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default AboutExtended;
