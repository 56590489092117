import React, { useState } from "react";
import img from "../../assets/events-vector.png";
import Card from "../Events/Card";
import eventDetails from "./eventdetails";
import "../Events/Events.css";
import oureventssvg from "../../assets/ourevents.svg"
import eyespng from "../../assets/eyes.png";
import { Link } from "react-router-dom";
import { Slide, Zoom } from "react-awesome-reveal";

const { data1, data2 } = eventDetails;

const Event = () => {
  const [selectedDay, setSelectedDay] = useState("day1");

  const handleDay1Click = () => setSelectedDay("day1");
  const handleDay2Click = () => setSelectedDay("day2");

  const renderCards = () => {
    if (selectedDay === "day1") {
      return (
        <>
          {data1.map((item) => (
            <Zoom triggerOnce>
            <Card
              topic={item.title}
              content={item.description}
              image={item.image}
              link={item.link}
            />
            </Zoom>
          ))}
        </>
      );
    } else {
      return (
        <>
          {data2.map((item) => (
            <Zoom triggerOnce>
            <Card
              topic={item.title}
              content={item.description}
              image={item.image}
              link={item.link}
            />
            </Zoom>
          ))}
        </>
      );
    }
  };

  return (
    <section>
      <div class="bg-[#F7F0D4] py-8">
        <div class="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div class="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 justify-center md:justify-start">
            {/* <p class="ml-2 uppercase tracking-loose">Presenting</p> */}
            {/* <p class="text-6xl md:text-5xl leading-normal md:leading-relaxed mb-2 font-black font-footer-heading1">
              OUR EVENTS
            </p> */}
            <Slide triggerOnce duration={400}><img src={oureventssvg} alt="our events"/></Slide>
            <Zoom triggerOnce className="my-4">
              <p class="text-sm md:text-base mb-4 mt-4">
                Discover the ultimate party experience at XTasy'23 - with non-stop music, dance, and masti, our events are not to be missed!
              </p>
            </Zoom>
            <div class="flex justify-center md:justify-start">
              <button
                onClick={handleDay1Click}
                class={`${
                  selectedDay === "day1"
                    ? "bg-[#F67A6F] text-white"
                    : "bg-transparent text-black"
                } mr-4 hover:bg-[#F67A6F] hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-black hover:border-transparent transition-all`}
              >
                26 May
              </button>
              <button
                onClick={handleDay2Click}
                class={`${
                  selectedDay === "day2"
                    ? "bg-[#F67A6F] text-white"
                    : "bg-transparent text-black"
                } hover:bg-[#F67A6F] hover:text-white rounded shadow hover:shadow-lg py-2 px-4 border border-black hover:border-transparent transition-all`}
              >
                27 May
              </button>
            </div>
            <div className="flex mt-8">
              <img src={eyespng} alt="eyes" height="auto" width="40px"/>
              <p className="pl-2">Please <Link to="/register" className="underline">Sign Up</Link> on the website to register for our Events!</p>
            </div>
          </div>
          <div class="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div class="container mx-auto w-full h-full">
              <div class="relative wrap overflow-hidden p-10 max-[640px]:p-4 h-full">
                {/* <div id="yellow-line" class="border-2-2 border-yellow-555 absolute h-full border"></div>
                <div id="yellow-line2" class="border-2-2 border-yellow-555 absolute h-full border"></div> */}
                <img class="mx-auto mt-10 right-timeline" src={img} />
                {renderCards()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Event;
