import React from "react";
import { motion } from "framer-motion";

import MagicCanvas from "../Magic/Magic";
import Navbar from "../Navbar/Navbar";
import Popup from "./Popup";
import { Fade } from "react-awesome-reveal";
import markthedates from '../../assets/markthedates.svg'
import { useAuth } from "../../auth";

const Hero = () => {
  const auth = useAuth(undefined)
  return (
    <Fade triggerOnce>
    <div className="flex bg-hero-backbg bg-center bg-cover h-screen items-center justify-center flex-col">
      <Navbar />
      {/* <Popup /> */}
      <MagicCanvas />
      <img style={{backgroundColor: "rgba(255,255,255,0.4)", borderRadius: "5px", padding: "5px"}} src={markthedates} className="w-[600px] absolute items-center justify-center bottom-24 max-[640px]:bottom-32 max-[640px]:w-[300px]" />
      <div className="min-[640px]:hidden text-md items-center justify-center text-[#1b8791] font-semibold absolute bottom-24 z" onClick={()=>{window.location.href= "#about"}}>
        PRESS TO SCROLL DOWN
      </div>
      <div className="min-[640px]:hidden xs:bottom-10 bottom-8 w-full flex justify-center items-center absolute">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-[#1b8791] flex justify-center items-start p-2">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-[#1b8791] mb-1"
            />
          </div>
        </a>
      </div>
      
    </div></Fade>
  );
};

export default Hero;
