const teamMembers = [

  {
    key: "2",
    name: "Chehak Joneja",
    position: "Events Curation Lead",
    image: "https://example.com/amy-lee.jpg",
    twitter: "https://twitter.com/amylee",
    instagram: "https://www.instagram.com/ceee__jayyy/",
    linkedin: "https://www.linkedin.com/in/amylee",
    cardPhoto: require("../../assets/team/chehak.jpg"),
    bgColour: "#F0E68C", // Khaki
  },
  {
    key: "4",
    name: "Samridhi Kurar",
    position: "Production Lead",
    image: "https://example.com/emma-davis.jpg",
    twitter: "https://twitter.com/emmadavis",
    instagram: "https://www.instagram.com/samridhikurar/",
    linkedin: "https://www.linkedin.com/in/emmadavis",
    cardPhoto: require("../../assets/team/samridh.jpg"),
    bgColour: "#F08080", // LightCoral
  },
  {
    key: "1",
    name: "Anushka Gagwari",
    position: "Operations Lead",
    image: "https://example.com/bob-johnson.jpg",
    twitter: "https://twitter.com/bobjohnson",
    instagram: "https://www.instagram.com/anushka.gagwari/",
    linkedin: "https://www.linkedin.com/in/bobjohnson",
    cardPhoto: require("../../assets/team/anush.jpg"),
    bgColour: "#ADD8E6", // LightBlue
  },

  {
    key: "6",
    name: "Riya Chauhan",
    position: "Communications Lead",
    image: "https://example.com/emma-davis.jpg",
    twitter: "https://twitter.com/emmadavis",
    instagram: "https://www.instagram.com/riyaa_chauhan12/",
    linkedin: "https://www.linkedin.com/in/emmadavis",
    cardPhoto: require("../../assets/team/riya.jpg"),
    bgColour: "#FFE4E1", // LightCoral
  },
  {
    key: "5",
    name: "Rupanshi Bhatnagar",
    position: "Creative Lead",
    image: "https://example.com/emma-davis.jpg",
    twitter: "https://twitter.com/emmadavis",
    instagram: "https://www.instagram.com/rupanshi31/",
    linkedin: "https://www.linkedin.com/in/emmadavis",
    cardPhoto: require("../../assets/team/rupa.jpg"),
    bgColour: "#FFDAB9", // LightCoral
  },
  {
    key: "7",
    name: "Mayank Hora",
    position: "Outreach Lead",
    image: "https://example.com/emma-davis.jpg",
    twitter: "https://twitter.com/emmadavis",
    instagram: "https://www.instagram.com/mayank.hora/",
    linkedin: "https://www.linkedin.com/in/emmadavis",
    cardPhoto: require("../../assets/team/hora.jpg"),
    bgColour: "#ADD8E6", // LightCoral
  },
  {
    key: "8",
    name: "Laksh Gambhir",
    position: "Digital Design Lead",
    image: "https://example.com/emma-davis.jpg",
    twitter: "https://twitter.com/emmadavis",
    instagram: "https://www.instagram.com/lakshgambhr/",
    linkedin: "https://www.linkedin.com/in/emmadavis",
    cardPhoto: require("../../assets/team/laksh.jpeg"),
    bgColour: "#F0E68C", // LightCoral
  },
];

export default teamMembers;
