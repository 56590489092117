import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./auth";
import AnimatedCursor from "react-animated-cursor";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";

import Footer from "./components/Footer/Footer";
import Event from "./components/Events/Event";
import Team2 from "./components/Team2/Team2";
import Pronite from "./components/Pronite/Pronite";
import RegisterPage from "./pages/RegisterPage";
import AboutExtended from "./components/About/AboutExtended";
import SigninPage from "./pages/SigninPage";
import Voting from "./pages/voting";


function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AnimatedCursor
          innerSize={8}
          outerSize={15}
          color="0,0,0"
          outerAlpha={0}
          innerScale={2}
          outerScale={5}
        />
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <>
                  <Hero />
                  <AboutExtended />
                  <Footer />
                </>
              }
            />
            <Route path="register" element={<RegisterPage />} />
            <Route
              path="events"
              element={
                <>
                  <Navbar />
                  <Event />
                  <Footer />
                </>
              }
            />
            <Route
              path="team"
              element={
                <>
                  <Navbar />
                  <Team2 />
                  <Footer />
                </>
              }
            />
            <Route path="pronite" element={<Pronite />} />
            <Route path="/signin" element={<SigninPage />} />
            {/* <Route path="/voting" element={<Voting />} /> */}
            {/* <Route path="*" element={<NoPage />} />  */}
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;