const Design = [
    {
        key: "2",
        name: "Aman Singh",
        position: "Design Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AmanSinghDesign.jpg"),
        bgColour: "#FFB6C1", // Light Pink
    },
    {
        key: "2",
        name: "Gaurav Chahar",
        position: "Fest Organiser",
        image: "https://example.com/jane-smith.jpg",
        twitter: "https://twitter.com/janesmith",
        instagram: "https://www.instagram.com/shourya_veer_dua/",
        linkedin: "https://www.linkedin.com/in/janesmith",
        cardPhoto: require("../../assets/team/headphoto/GauravChaharDesign.jpg"),
        bgColour: "#FFE4E1", // MistyRose
    },
];

export default Design;

