import React from "react";
import { Link } from "react-router-dom";
import rahuldua from "../../assets/artistRahulDua.svg";
import artistDj from "../../assets/artistDj.svg";
import artistMagician from "../../assets/artistMagician.svg";
import artistBand from "../../assets/artistBand.svg";

// style={{ backgroundImage: "url('https://mir-s3-cdn-cf.behance.net/project_modules/fs/a616be166475211.6419248d1c917.gif')" }}

const Pronite = () => {
  return (
    <main className="min-h-screen bg-cover bg-center bg-pronitebg">
      <div className="container mx-auto max-[640px]:px-0 py-48 max-[640px]:py-16">
        <div className="flex justify-center mt-1 goback hover:scale-110 transition-all">
          <div
            onClick={() => {
              window.history.back();
            }}
            className="inline-block font-bold border-2 px-3 py-1 rounded-xl bg-[#FFB097] hover:bg-[#f6805c] border-black transition-all duration-300 ease-in-out"
          >
            GO BACK
          </div>
        </div>
        {/* <div className="flex max-[640px]:flex-col flex-row justify-between"> */}
        <div className="grid grid-cols-2 max-[640px]:grid-cols-1 gap-2 w-full">
          <div className="flex items-center justify-center pl-2">
            <img
              src={rahuldua}
              alt="StandUp Comedy Artist"
              className="h-5/6 w-auto hover:scale-110 transition-all"
            />
          </div>
          <div className="flex items-center justify-center pl-2">
            <img
              src={artistMagician}
              alt="StandUp Comedy Artist"
              className="h-5/6 w-auto hover:scale-110 transition-all"
            />
          </div>
          <div className="flex items-center justify-center pl-2">
            <img
              src={artistDj}
              alt="StandUp Comedy Artist"
              className="h-5/6 w-auto hover:scale-110 transition-all"
            />
          </div>

          <div className="flex items-center justify-center pl-2">
            <img
              src={artistBand}
              alt="StandUp Comedy Artist"
              className="h-5/6 w-auto hover:scale-110 transition-all"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Pronite;
