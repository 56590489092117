import React, { useEffect } from "react";
import insta from '../assets/instagram.png'
import shape from '../assets/Shape.png'
import SignUp from "../routes/SignUp"
import QR from '../assets/qr.jpeg';
import signupsvg from '../assets/signup.svg'
import { Rotate, Zoom } from "react-awesome-reveal";
import backarrowsvg from "../assets/backarrow.svg"
import Registermobile from "./Registermobile";
import { useAuth } from "../auth";
import { Navigate } from "react-router-dom";

const RegisterPage = () => {

  const auth = useAuth(undefined);

  useEffect(()=>{
    if(auth?.user) {
      window.alert("You are already loggedin! Please logout from menubar to visit this page.")
    }
  })

  return (
    <>
    {auth.user ? <Navigate to="/" /> :<>
    <div className="Register">
      <Rotate triggerOnce className="Register__insta"><img src={insta} alt="instagram" onClick={()=>{window.open("https://www.instagram.com/xtasy_bvcoe/","_blank")}} /></Rotate>
      <Zoom triggerOnce className="Register__heading" style={{right: "18%"}}><img src={signupsvg} width="300px"/></Zoom>
      <Zoom triggerOnce className="Register__info aboutTextFont">
        <div className="aboutTextFont">
          1. Download and Install the <strong>APP</strong> using this QR<br/><br/>
          <img src={QR} height='100px' width="100px" style={{marginLeft: "20%"}}/><br/>
          2. Click on <strong>SIGN UP</strong> (Enter as a New User only). Verify your mobile number by clicking on <strong>GET OTP</strong><br/><br/>
          3. On the top left corner of the main screen, tap on <strong>My Profile</strong> --> <strong>Personal Details</strong> --> <strong>User Details</strong> --> <strong>Username</strong><br/><br/>
          4. Sign up on our website using your <strong>unique</strong> app username.<br/><br/>
          <strong>Please note :</strong><br/>
          If you try to sign up using an existing Adda52 account or a false username, then you won't be able to get a pass on your Email ID.
        </div>
      </Zoom>
      <Zoom triggerOnce className="Register__backarrow">
        <img src={backarrowsvg} alt="back arrow" width="35px" onClick={()=>{window.history.back()}}/>
      </Zoom>
      <Zoom triggerOnce className="Register__button">
        <div>
          <p>Already Registered?</p>
          <button onClick={()=>{window.location.href = "signin"}} className="font-semibold border-2 px-3 py-1 my-2 rounded-xl bg-[#FFB097] border-black text-xl max-[640px]:text-base">Awesome, Sign In Here!</button>
        </div>
      </Zoom>
      <div className="Register__shape">
        <img height="100%" width="100%" src={shape} alt="instagram" />
        <Zoom triggerOnce>
          <div className="Register__form">
            <SignUp />
          </div>
        </Zoom>
      </div>
    </div>
    <Registermobile />
    </>}
    </>
  );
};

export default RegisterPage;
