const Coverage = [
    {
        key: "1",
        name: "Hitesh Gandhi",
        position: "Event Coverage Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/HiteshGandhiEventCoverage.jpg"),
        bgColour: "#B0E0E6", // Powder Blue
    },
];

export default Coverage;

