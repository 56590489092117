const Sponsorship = [
    {
        key: "1",
        name: "Riya Ghaloth",
        position: "Sponsorship Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RiyaGhalothSponsorship_.jpg"),
        bgColour: "#E0FFFF", // Light Cyan
    },


];

export default Sponsorship;

