const Security = [
    {
        key: "1",
        name: "Rishab Gulia",
        position: "Security Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RishabGuliaSecurity.jpg"),
        bgColour: "#FFFF00", // Yellow
    },
    {
        key: "2",
        name: "Rishabh Tyagi",
        position: "Security Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/RishabhTyagiSecurity.jpg"),
        bgColour: "#FFE4B5", // Moccasin
    },
];

export default Security;

