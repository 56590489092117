const teamMembers2 = [
    {
        key: "1",
        name: "Lakshay Manchanda",
        position: "Technical Lead",
        image: "https://example.com/john-doe.jpg",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/__geekyguy__/",
        linkedin: "",
        cardPhoto: require('../../assets/team/lakshay.jpg'),
        bgColour: "#FFDAB9" // PeachPuff
    },
    {
        key: "2",
        name: "Aryan Chopra",
        position: "Website Developer",
        image: "https://example.com/jane-smith.jpg",
        twitter: "https://twitter.com/janesmith",
        instagram: "https://www.instagram.com/aryann_chopraa/",
        linkedin: "https://www.linkedin.com/in/janesmith",
        cardPhoto: require('../../assets/team/chopra4.jpg'),
        bgColour: "#FFE4E1" // MistyRose
    },
    {
        key: "3",
        name: "Sanidhya Singh",
        position: "Website Developer",
        image: "https://example.com/bob-johnson.jpg",
        twitter: "https://twitter.com/bobjohnson",
        instagram: "https://www.instagram.com/sanidhya.web/",
        linkedin: "https://www.linkedin.com/in/bobjohnson",
        cardPhoto: require('../../assets/team/sani.jpg'),
        bgColour: "#ADD8E6" // LightBlue
    },
    {
        key: "4",
        name: "Sparsh",
        position: "Website Developer",
        image: "https://example.com/bob-johnson.jpg",
        twitter: "https://twitter.com/bobjohnson",
        instagram: "https://www.instagram.com/humanafterall_l/",
        linkedin: "https://www.linkedin.com/in/bobjohnson",
        cardPhoto: require('../../assets/team/sparsh.jpg'),
        bgColour: "#FFDAB9"
    }

];

export default teamMembers2;