import React from "react";
import TeamCard from "./TeamCard";
import TeamCard2 from "./TeamCard2";
import teamMembers from "./teamDetails";
import teamMember2 from "./TeamDetails2";
import teamMember1 from "./teamDetails1";
import HeadDetails from "./HeadDetails";
import Decorations from "./Decorations";
import Security from "./Security";
import Events from "./Events";
import Social from "./Social";
import Promoter from "./Promoter";
import Content from "./Content";
import Food from "./Food";
import Design from "./Design";
import Video from "./Video";
import Communications from "./Communications";
import Sponsorship from "./Sponsorship";
import Coverage from "./Coverage";




const Team2 = () => {


  return (
    <div>
      <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-10">
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
            Meet The Dream Team
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {teamMember1.map((teamMember1) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={teamMember1.name}
            >
              <TeamCard2
                name={teamMember1.name}
                position={teamMember1.position}
                image={teamMember1.image}
                twitter={teamMember1.twitter}
                instagram={teamMember1.instagram}
                linkedin={teamMember1.linkedin}
                cardPhoto={teamMember1.cardPhoto}
                bgColor={teamMember1.bgColour}
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {teamMembers.map((teamMember) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={teamMember.name}
            >
              <TeamCard2
                name={teamMember.name}
                position={teamMember.position}
                image={teamMember.image}
                twitter={teamMember.twitter}
                instagram={teamMember.instagram}
                linkedin={teamMember.linkedin}
                cardPhoto={teamMember.cardPhoto}
                bgColor={teamMember.bgColour}
              />
            </div>
          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Promoters
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {Promoter.map((Promoter) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Promoter.name}
            >
              <TeamCard2
                name={Promoter.name}
                position={Promoter.position}
                image={Promoter.image}
                twitter={Promoter.twitter}
                instagram={Promoter.instagram}
                linkedin={Promoter.linkedin}
                cardPhoto={Promoter.cardPhoto}
                bgColor={Promoter.bgColour}

              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Team Behind The Website
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {teamMember2.map((teamMember2) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={teamMember2.name}
            >
              <TeamCard2
                name={teamMember2.name}
                position={teamMember2.position}
                image={teamMember2.image}
                twitter={teamMember2.twitter}
                instagram={teamMember2.instagram}
                linkedin={teamMember2.linkedin}
                cardPhoto={teamMember2.cardPhoto}
                bgColor={teamMember2.bgColour}
              />
            </div>

          ))}
        </div>

        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Event Management Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {Events.map((Events) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Events.name}
            >
              <TeamCard
                name={Events.name}
                position={Events.position}
                instagram={Events.instagram}
                cardPhoto={Events.cardPhoto}
                bgColor={Events.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The  Food and Beverages Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Food.map((Food) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Food.name}
            >
              <TeamCard
                name={Food.name}
                position={Food.position}
                instagram={Food.instagram}
                cardPhoto={Food.cardPhoto}
                bgColor={Food.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Security Team
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Security.map((Security) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Security.name}
            >
              <TeamCard
                name={Security.name}
                position={Security.position}
                instagram={Security.instagram}
                cardPhoto={Security.cardPhoto}
                bgColor={Security.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Social Media Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Social.map((Social) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Social.name}
            >
              <TeamCard
                name={Social.name}
                position={Social.position}


                instagram={Social.instagram}

                cardPhoto={Social.cardPhoto}
                bgColor={Social.bgColour}

              />
            </div>

          ))}
        </div>

        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Decorations Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Decorations.map((Decorations) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Decorations.name}
            >
              <TeamCard
                name={Decorations.name}
                position={Decorations.position}
                image={Decorations.image}
                twitter={Decorations.twitter}
                instagram={Decorations.instagram}
                linkedin={Decorations.linkedin}
                cardPhoto={Decorations.cardPhoto}
                bgColor={Decorations.bgColour}

              />
            </div>

          ))}
        </div>

        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Communications Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Communications.map((Communications) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Communications.name}
            >
              <TeamCard
                name={Communications.name}
                position={Communications.position}


                instagram={Communications.instagram}

                cardPhoto={Communications.cardPhoto}
                bgColor={Communications.bgColour}

              />
            </div>

          ))}
        </div>




        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Sponsorship Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mb-8">
          {Sponsorship.map((Sponsorship) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Sponsorship.name}
            >
              <TeamCard
                name={Sponsorship.name}
                position={Sponsorship.position}
                instagram={Sponsorship.instagram}
                cardPhoto={Sponsorship.cardPhoto}
                bgColor={Sponsorship.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Design Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Design.map((Design) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Design.name}
            >
              <TeamCard
                name={Design.name}
                position={Design.position}
                instagram={Design.instagram}
                cardPhoto={Design.cardPhoto}
                bgColor={Design.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Content and Documentation Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-8">
          {Content.map((Content) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Communications.name}
            >
              <TeamCard
                name={Content.name}
                position={Content.position}
                instagram={Content.instagram}
                cardPhoto={Content.cardPhoto}
                bgColor={Content.bgColour}
              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Event Coverage Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-7">
          {Coverage.map((Coverage) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Coverage.name}
            >
              <TeamCard
                name={Coverage.name}
                position={Coverage.position}


                instagram={Coverage.instagram}

                cardPhoto={Coverage.cardPhoto}
                bgColor={Coverage.bgColour}

              />
            </div>

          ))}
        </div>
        <div className="text-center pb-12">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 mt-20">
            Meet The Video Editing Heads
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-7">
          {Video.map((Video) => (
            <div
              className="relative w-full rounded-lg overflow-hidden flex flex-col justify-center items-center mx-3"
              key={Video.name}
            >
              <TeamCard
                name={Video.name}
                position={Video.position}


                instagram={Video.instagram}

                cardPhoto={Video.cardPhoto}
                bgColor={Video.bgColour}

              />
            </div>

          ))}
        </div>
      </section>
    </div>
  );
};

export default Team2;
