const Content = [
    {
        key: "1",
        name: "Anushka Sharma",
        position: "CnD Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/AnushkaSharmaC_D.jpg"),
        bgColour: "#98FB98", // Pale Green
    },
    {
        key: "2",
        name: "Ekagrah Grover",
        position: "CnD Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/EkagrahGroverC_D.jpg"),
        bgColour: "#AFEEEE", // Pale Turquoise
    },
];

export default Content;

