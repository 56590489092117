import { React, useEffect } from 'react';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import '../Team2/TeamCard.css'
import barcode from '../../assets/teamcard/barcode.svg'
import stamp from '../../assets/teamcard/stamp.svg'
import xtasylogo from '../../assets/teamcard/xtasy-logo.png'
import logo from '../../assets/teamcard/logo-team.png'
import { Slide, Zoom } from 'react-awesome-reveal';

const TeamCard = ({ name, position, image, twitter, instagram, linkedin, cardPhoto, bgColor }) => {
  return (
    <div className='card' style={{ backgroundColor: bgColor }}>
      <Zoom direction='left' duration={500} triggerOnce>
        <div className='card-photo bg-cover bg-center' style={{ backgroundImage: `url(${cardPhoto})` }}></div>

      </Zoom>
      <div className='line1'></div>
      <div className='year'>2023</div>
      <Slide triggerOnce duration={500} className='name'>{name}</Slide>
      <Slide triggerOnce duration={500} direction='right' className='position'>{position}</Slide>
      <div className='line2'></div>
      <img src={xtasylogo} className='xtasy' />
      <img src={stamp} className='stamp' />
      <img src={logo} className='logo' />
      <img src={barcode} className='barcode' />
    </div>

  );
};

export default TeamCard;
