const Social = [
    {
        key: "1",
        name: "Paakhi Sharma",
        position: "Social Media Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/PaakhiSharmaSocialMedia.jpg"),
        bgColour: "#98FB98", // Deep Pink
    },
    {
        key: "2",
        name: "Gautmi Tomar",
        position: "Social Media Head",
        image: "",
        twitter: "https://twitter.com/johndoe",
        instagram: "https://www.instagram.com/shivamkapurr/",
        linkedin: "https://www.instagram.com/shivamkapurr/",
        cardPhoto: require("../../assets/team/headphoto/GautmiTomarSocialMedia.jpg"),
        bgColour: "#DDA0DD", // Plum
    },
];

export default Social;

