const teamMember1 = [
  {
    key: "1",
    name: "Shivam Kapur",
    position: "Fest Organiser",
    image: "",
    twitter: "https://twitter.com/johndoe",
    instagram: "https://www.instagram.com/shivamkapurr/",
    linkedin: "https://www.instagram.com/shivamkapurr/",
    cardPhoto: require("../../assets/team/shivam.jpg"),
    bgColour: "#FFDAB9", // PeachPuff
  },
  {
    key: "2",
    name: "Shourya Veer Dua",
    position: "Fest Organiser",
    image: "https://example.com/jane-smith.jpg",
    twitter: "https://twitter.com/janesmith",
    instagram: "https://www.instagram.com/shourya_veer_dua/",
    linkedin: "https://www.linkedin.com/in/janesmith",
    cardPhoto: require("../../assets/team/Dua.jpg"),
    bgColour: "#FFE4E1", // MistyRose
  },
];

export default teamMember1;

