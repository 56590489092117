import React, { useState } from "react";
import { styles } from "../../styles";
import close from "../../assets/close.svg";
import menu from "../../assets/menu.svg";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./navbar.css";
import { useAuth } from "../../auth";
import * as apiService from "../../api-service";
import { useEffect } from "react";

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);

  const [userDetails, setUserDetails] = useState({});

  const auth = useAuth(undefined);
  const [dataState, setDataState] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (!auth.loading) {
        if (auth.user) {
          setDataState("loading");
          try {
            const { addUsername, name } = await apiService.getUserData({
              userId: auth.user?.uid,
            });
            setDataState("success");
            setUserDetails({ addUsername, name });
            setDataState("success");
          } catch {
            setDataState("error");
          }
        }
      }
      console.log(dataState);
    })();
  }, [auth.user, auth.loading]);

  const navLinks = [
    {
      id: "events",
      title: "EVENTS",
      path: "/events",
    },
    // {
    //   id: "gallery",
    //   title: "GALLERY",
    //   path: "/gallery",
    // },
    {
      id: "team",
      title: "TEAM",
      path: "/team",
    },
    {
      id: "pronite",
      title: "PRONITE",
      path: "/pronite",
    },
  ];

  return (
    <nav
      className={`${styles.paddingX} w-full flex items-start py-5 top-0 z-20 navbar`}
    >
      <div className="flex w-full justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img src={logo} alt="logo" className="w-16 h-16 object-fill" />
        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-16 navbarlist items-center">
          {navLinks.map((link) => (
            <li
              key={link.id}
              className={`${
                active === link.title ? "text-gray-700" : "text-black"
              } text-[17px] font-bold cursor-pointer hover:scale-110 transition-all`}
            >
              <Link to={link.path} onClick={() => setActive(link.title)}>
                {link.title}
              </Link>
            </li>
          ))}
          {!auth.user && (
            <div className="max-[640px]:hidden items-center justify-center">
              <button
                className="font-bold border-2 px-3 py-1 rounded-xl bg-[#FFB097] border-black hover:scale-110 transition-all"
                onClick={() => {
                  window.location.href = "signin";
                }}
              >
                LOGIN
              </button>
            </div>
          )}
          {auth.user && dataState === "success" && (
            <div className="max-[640px]:hidden items-center justify-center">
              <button
                className="font-bold border-2 px-3 py-1 rounded-xl bg-[#FFB097] border-black uppercase"
                onClick={auth.signOut}
              >
                Logout, {userDetails.name.split(" ")[0]}
              </button>
            </div>
          )}
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] object-contain cursor-pointer transition-all"
            onClick={() => setToggle(!toggle)}
          />
          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } transition-all p-6 absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl bg-[#FFF9C8]`}
          >
            <ul className="list-none flex justify-end items-start flex-col gap-4">
              {navLinks.map((link) => (
                <li
                  key={link.id}
                  className={`${
                    active === link.title ? "text-white" : "text-secondary"
                  } font-poppins font-medium cursor-pointer text-[16px]`}
                >
                  <Link
                    to={link.path}
                    onClick={() => {
                      setToggle(!toggle);
                      setActive(link.title);
                    }}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
              <div className="items-center justify-center">
                {!auth.user && (
                  <button
                    className="font-semibold text-[16px] border-2 px-1 py-1 rounded-xl bg-[#FFB097] border-black"
                    onClick={() => {
                      window.location.href = "signin";
                    }}
                  >
                    LOGIN
                  </button>
                )}
                {auth.user && dataState === "success" && (
                  <button
                    className="font-semibold text-[16px] border-2 px-1 py-1 rounded-xl bg-[#FFB097] border-black uppercase"
                    onClick={auth.signOut}
                  >
                    Logout, {userDetails.name.split(" ")[0]}
                  </button>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
