const Promoter = [
    {
        key: "1",
        name: "Shivam Sharma",
        position: "Promoter",
        image: "https://example.com/bob-johnson.jpg",
        twitter: "https://twitter.com/bobjohnson",
        instagram: "https://instagram.com/spiritual_.gaanja/",
        linkedin: "https://www.linkedin.com/in/bobjohnson",
        cardPhoto: require('../../assets/team/headphoto/ShivamSharma_.jpg'),
        bgColour: "#ADD8E6" // LightBlue
    },
    {
        key: "2",
        name: "Dhruv Kamshetty",
        position: "Promoter",
        image: "https://example.com/jane-smith.jpg",
        twitter: "https://twitter.com/janesmith",
        instagram: "https://instagram.com/dhruvffff_/",
        linkedin: "https://www.linkedin.com/in/janesmith",
        cardPhoto: require('../../assets/team/headphoto/DhruvKamshetty.jpg'),
        bgColour: "#FFE4E1" // MistyRose
    },
    {
        key: "4",
        name: "Jatin Gill",
        position: "Promoter",
        image: "https://example.com/bob-johnson.jpg",
        twitter: "https://twitter.com/bobjohnson",
        instagram: "https://instagram.com/jatin.g_01/",
        linkedin: "https://www.linkedin.com/in/bobjohnson",
        cardPhoto: require('../../assets/team/headphoto/JatinGill.jpg'),
        bgColour: "#F08080"
    },
    {
        key: "5",
        name: "Yash Dhanwaria",
        position: "Promoter",
        image: "https://example.com/bob-johnson.jpg",
        twitter: "https://twitter.com/bobjohnson",
        instagram: "https://instagram.com/yashdhanwaria4/",
        linkedin: "https://www.linkedin.com/in/bobjohnson",
        cardPhoto: require('../../assets/team/headphoto/YashDhanwaria.jpeg'),
        bgColour: "#FFE4E1"
    }

];

export default Promoter;