import React from "react";
import "./Events.css";
import { useAuth } from "../../auth";

const Card = (props) => {

  console.log(props.link)

  const auth = useAuth(undefined);

  const handleClick = (link) => {
    if(auth?.user) {
      window.open(link, "_blank")
    } else {
      alert("PLEASE REGISTER ON OUR WEBSITE, THEN YOU CAN REGISTER IN EVENTS!");
      window.location.href = "register"
    }
  }

  return (
    <div className="relative w-750 h-100 aboutTextFont flex flex-col justify-center items-center p-6 m-6 rounded-xl border-2">
      <img
        className="h-32 sm:h-28 md:h-60 lg:h-60 object-contain mb-4 rounded"
        src={props.image}
        alt="Banner"
      />
      <div className="text-center">
        {/* <h2 className="text-2xl sm:text-2xl md:text-2xl lg:text-5xl font-extrabold text-gray-900 mb-2">
          {props.topic}
        </h2> */}
        <p className="text-md  sm:text-base md:text-lg lg:text-lg text-black mb-4">
          {props.content}
        </p>
        <div>
          <div
            onClick={props.link && (()=>{handleClick(props.link)})}
            className={`inline-block px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-[#F67A6F] hover:bg-[#F65749] ${!props.link && "bg-[#474747] hover:bg-[#474747]"}`}
          >
            {props.link ? "Register Now" : "Stay Tuned"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
