const data1 = [
  // {
  //   title: "Battle of Bands",
  //   description:
  //     "There’s nothing better than watching the best student bands performing in a blazing event to claim the first spot. That’s “Battle Of Bands'' for you, ladies and gentlemen, an event full of enthusiasm and nonstop fun.",
  //   image: require("../../assets/eventssvg/bandishnew.svg").default,
  // },
  // {
  //   title: "Street Dance Battle",
  //   description:
  //     "The dance battles are always a crowd-pleaser, with some of the most talented dancers in the region competing for the top prize. Prepare yourselves to witness the never seen before dance moves.",
  //   image: require("../../assets/eventssvg/streetwarsnew.svg").default,
  // },
  {
    title: "Concordia",
    description:
      "A Western Group Singing competition where we hope to see singers spread an aura of pleasant harmonies captivating the audience with their unparalleled singing, giving a new dimension to songs!",
    image: require("../../assets/eventssvg/concordianew.svg").default,
    link: "https://forms.gle/ixQnhhg2ewoPFt9H6"
  },
  // {
  //   title: "Jodi Night",
  //   description:
  //     "A night full of dancing which celebrates love, life and friendship. Jodi Night is the secret highlight of entire XTasy’23. Couple things you know!!",
  //   image: require("../../assets/eventssvg/promtimenew.svg").default,
  // },
  // {
  //   title: "Stage Play",
  //   description:
  //     "With just a pinch of drama and a dash of emotion, teams will put forward their best acting performances. Get ready to sparkle in your kurtas and spread the true essence of nukkad to the masses.",
  //   image: require("../../assets/bandishtypo.svg").default,
  // },
  {
    title: "Lifestyle (Fashion Show)",
    description:
      "The Fashion Show aims to bring together fashion enthusiasts to the latest ideas, styles, and trends to show to the audience. You will have the opportunity to connect with each other and learn from other teams, and you can immerse yourself in your fashion",
    image: require("../../assets/eventssvg/lifestylenew.svg").default,
    link: "https://forms.gle/6ZDeuvTvZb3Aj8Gr8"
  },
  {
    title: "Click It",
    description:
      "Say Cheese!!!..Share your point of view! Click and upload snapshots of your experiences of the fest. Best photographs will take this title home.",
    image: require("../../assets/eventssvg/clickitnew.svg").default,
    link: "https://forms.gle/NrThccuvNGVoDkM5A"
  },
  // {
  //   title: "Pop Quiz",
  //   description:
  //     "The theme of XTasy’23? Yes! That’s right. A retro-themed popular culture quiz where all the pop addicts get to prove their knowledge. Sounds good right?",
  //   image: require("../../assets/eventssvg/popquiznew.svg").default,
  // },
  {
    title: "Art Attack",
    description:
      "Artists from all over the region will showcase their talents in a range of mediums. A session full of joy, colours and brightness which will allow participants to express themselves through the art they create.",
    image: require("../../assets/eventssvg/artattacknew.svg").default,
    link: "https://forms.gle/ADHtbkzEAQem641Y6"
  },
  {
    title: "Arcade Ancestry",
    description:
      "Enter the world of classic e-games, like mario kartand many more. Do you have what it takes to reach the top? Yes? The arena is all yours then.Let  the nostalgia kick in!",
    image: require("../../assets/eventssvg/arcadenew.svg").default,
    link: "https://forms.gle/umWhqYBW2cJvf85b8"
  },

  // {
  //   title: "Rap Battle",
  //   description:
  //     "The bottle has been rolled. Who’s gonna take over the stage? Put on your hip hop hats for this fierce battle amongst the best of the city’s rappers and be the one to claim that “gully boy” title.",
  //   image: require("../../assets/eventssvg/rapbattlenew.svg").default,
  // },
  // {
  //   "title": "StoryTelling",
  //   "description": "Narrators will completely immerse you in their worlds by sharing their life experiences in this StoryTelling session."
  // },
  {
    title: "Articulate Arena - Debate",
    description:
      "Take a deep breath, Hold on, Think, and you are good to go. Why? because you only get one minute to voice your opinions on a given topic and win this title. ",
    image: require("../../assets/ARTICULATE.png"),
    link: "https://forms.gle/PicAGnZAFbBrb5267"
  },
  {
    title: "Groove Gala",
    description:
      `It is an event for stage performances including two categories which are Solo dance performance and Duet dance performance.`,
    image: require("../../assets/eventssvg/groove.svg").default,
    link: "https://docs.google.com/forms/d/1h-FsvWHRO27l-X9fEbX_Tp9X0vLGf9CTzvSs9VnPgkg/edit"
  },
  {
    title: "XTasy Carnival",
    description:
      "Enter the world of classic e-games, like mario kartand many more. Do you have what it takes to reach the top? Yes? The arena is all yours then.Let  the nostalgia kick in!",
    image: require("../../assets/eventssvg/carnivalnew.svg").default,
    link: undefined
  },
];
const data2 = [
  {
    title: "Band-ish",
    description:
      "There’s nothing better than watching the best student bands performing in a blazing event to claim the first spot. That’s “Battle Of Bands'' for you, ladies and gentlemen, an event full of enthusiasm and nonstop fun.",
    image: require("../../assets/eventssvg/bandishnew.svg").default,
    link: "https://forms.gle/hy15vQXynNoSjiXw7"
  },
  {
    title: "Street Wars",
    description:
      "The dance battles are always a crowd-pleaser, with some of the most talented dancers in the region competing for the top prize. Prepare yourselves to witness the never seen before dance moves.",
    image: require("../../assets/eventssvg/streetwarsnew.svg").default,
    link: "https://forms.gle/EyenHEzHfNKad6sj9"
  },
  // {
  //   title: "Concordia",
  //   description:
  //     "A western group singing event in which a maximum of two acoustic instruments will be allowed.",
  //   image: require("../../assets/eventssvg/concordianew.svg").default,
  // },
  {
    title: "Prom Time",
    description:
      "A night full of dancing which celebrates love, life and friendship. Jodi Night is the secret highlight of entire XTasy’23. Couple things you know!!",
    image: require("../../assets/eventssvg/promtimenew.svg").default,
    link: "https://forms.gle/xkRYB6zg9uJg2fKZA"
  },
  // {
  //   title: "Stage Play",
  //   description:
  //     "With just a pinch of drama and a dash of emotion, teams will put forward their best acting performances. Get ready to sparkle in your kurtas and spread the true essence of nukkad to the masses.",
  //   image: require("../../assets/bandishtypo.svg").default,
  // },
  // {
  //   title: "Lifestyle (Fashion Show)",
  //   description:
  //     "Our fashion shows are always a highlight of the cultural fest, with the latest trends in fashion on display. The ones following the most latest trend and style will be crowned as Mr. and Ms. XTasy’23.",
  //   image: require("../../assets/eventssvg/lifestylenew.svg").default,
  // },
  {
    title: "Click It",
    description:
      "Say Cheese!!!..Share your point of view! Click and upload snapshots of your experiences of the fest. Best photographs will take this title home.",
    image: require("../../assets/eventssvg/clickitnew.svg").default,
    link: "https://forms.gle/NrThccuvNGVoDkM5A"
  },
  {
    title: "Pop Quiz",
    description:
      "The theme of XTasy’23? Yes! That’s right. A retro-themed popular culture quiz where all the pop addicts get to prove their knowledge. Sounds good right?",
    image: require("../../assets/eventssvg/popquiznew.svg").default,
    link: "https://forms.gle/Gt3MAphf4hdG7K8p9"
  },
  // {
  //   title: "Art Attack",
  //   description:
  //     "Artists from all over the region will showcase their talents in a range of mediums. A session full of joy, colours and brightness which will allow participants to express themselves through the art they create.",
  //   image: require("../../assets/eventssvg/artattacknew.svg").default,
  // },
  // {
  //   title: "Arcade Ancestry",
  //   description:
  //     "Enter the world of classic e-games, like mario kartand many more. Do you have what it takes to reach the top? Yes? The arena is all yours then.Let  the nostalgia kick in!",
  //   image: require("../../assets/eventssvg/arcadenew.svg").default,
  // },

  {
    title: "Mic Drop",
    description:
      "The bottle has been rolled. Who’s gonna take over the stage? Put on your hip hop hats for this fierce battle amongst the best of the city’s rappers and be the one to claim that “gully boy” title.",
    image: require("../../assets/eventssvg/rapbattlenew.svg").default,
    link: "https://forms.gle/tqtnfNiAUJTyqAfj9"
  },
  // {
  //   "title": "StoryTelling",
  //   "description": "Narrators will completely immerse you in their worlds by sharing their life experiences in this StoryTelling session."
  // },
  // {
  //   title: "Articulate Arena - Debate",
  //   description:
  //     "Take a deep breath, Hold on, Think, and you are good to go. Why? because you only get one minute to voice your opinions on a given topic and win this title. ",
  //   image: require("../../assets/eventssvg/carnivalnew.svg").default,
  // },
  {
    title: "XTasy Carnival",
    description:
      "Enter the world of classic e-games, like mario kartand many more. Do you have what it takes to reach the top? Yes? The arena is all yours then.Let  the nostalgia kick in!",
    image: require("../../assets/eventssvg/carnivalnew.svg").default,
    link: undefined
  },
];

export default { data1, data2 };
